import { Site } from './site.model';

export interface Device {
  id: string;
  displayLabel: string;
  displayLabels: object;
  defaultLocale: string;
  status: string;
  macAddress: string;
  installerContactId: string;
  gatewayType: string;
  gatewayId: string;
  crmId: string;
  siteId: string;
  site: Site;
  host: string;
  port: number;
  includeInCalcs: string;
  emulated: string;
  signalStrength: number;
  equipmentIds: string[];
  notes: string;
  intervalDataTypes: object;
  meters: object[];
  controlSets: object[];
  lastSrMetadata: any;
}

export const mappedTypes = [
  { type: 'FIXED', displayLabel: 'device.mapped_types.fixed' },
  { type: 'RANDOM', displayLabel: 'device.mapped_types.random' },
];

export const statuses = [
  { name: 'ACTIVE', displayLabel: 'device.statuses.active' },
  { name: 'INACTIVE', displayLabel: 'device.statuses.inactive' },
  { name: 'DECOMMISSIONED', displayLabel: 'device.statuses.decommissioned' },
  { name: 'ORPHANED', displayLabel: 'device.statuses.orphaned' },
];

export class IntervalDataTypes {
  constructor(data) {
    for (let channel in data) {
      const interval = data[channel];
      this[channel] =
        interval.type === 'FIXED' ? `${interval.type}(${interval.N})` : `${interval.type}(${interval.N},${interval.M})`;
    }
  }
}

export const DEFAULT_DEVICE = {
  id: '',
  displayLabel: '',
  displayLabels: {},
  status: '',
  macAddress: '',
  installerContactId: '',
  gatewayType: '',
  gatewayId: '',
  crmId: '',
  siteId: '',
  site: {
    id: '',
    displayLabels: {},
    updateDttm: '',
    createDttm: '',
    address1: '',
    address2: '',
    city: '',
    country: '',
    state: '',
    postalCode: '',
    latitude: 0,
    longitude: 0,
    organizationId: '',
    organization: {},
    displayLabel: '',
  },
  host: '',
  port: 0,
  includeInCalcs: '',
  emulated: '',
  signalStrength: 0,
  equipmentIds: [],
  notes: '',
  defaultLocale: '',
  intervalDataTypes: {},
  meters: [],
  controlSets: [],
  lastSrMetadata: {},
};

export interface PostDevice {
  displayLabels: object;
  siteId: string;
  equipmentIds: string[];
  status: string;
  defaultLocale?: string;
  macAddress?: string;
  installerContactId?: string;
  gatewayType?: string;
  gatewayId?: string;
  crmId?: string;
  host?: string;
  port?: number;
  includeInCalcs?: boolean;
  emulated?: boolean;
  signalStrength?: number;
  notes?: string;
  intervalDataTypes?: object;
}
