import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { MeasuredPoint } from '../model/measured-point.model';

@Injectable({
  providedIn: 'root',
})
export class MeasuredPointsService {
  constructor(private apiService: ApiService) {}

  async createMeasuredPoints(points: MeasuredPoint[]): Promise<any> {
    return await this.apiService.post('measured-points', points);
  }
}
