import { Injectable } from '@angular/core';
import { AnalyticsService } from 'ngx-shared-services';
import { GlobalNavService, User } from 'ngx-global-nav';
import {BehaviorSubject, Subject} from 'rxjs';

/**
 * A service to easily implement mixpanel tracking
 */
@Injectable()
export class MixPanelService {
  EVENTS = {
    'LOGIN': 'Login',
    'VIEW_DEVICE_ADMIN' : 'View Device Admin',
    'VIEW_DEVICE': 'View Device',
    'CREATE_DEVICE' : 'Create Device',
    'EDIT_DEVICE': 'Edit Device',
    'DELETE_DEVICE': 'Delete Device',
    'SAVE_CREATE_DEVICE': 'Save New Device',
    'SAVE_EDIT_DEVICE': 'Save Edit Device'
    // add new events here
  };
  APPLICATION_NAME = 'Device Admin'
  isInit = false
  userInformation$ = new BehaviorSubject<User>(null);

  constructor(private analyticsService: AnalyticsService, private globalNavService: GlobalNavService) {
    this.globalNavService.userData$.subscribe((user) => {
      if (user && !this.isInit) {
        this.userInformation$.next(user);
        this.analyticsService.init(user.user_id, this.APPLICATION_NAME);
        this.analyticsService.setPage(this.APPLICATION_NAME);
        this.isInit = true;
      }
    })
  }

  viewPage() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.VIEW_DEVICE_ADMIN, {})
      }
    })
  }

  viewDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.VIEW_DEVICE, {})
      }
    })
  }

  createDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.CREATE_DEVICE, {})
      }
    })
  }

  editDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.EDIT_DEVICE, {})
      }
    })
  }

  deleteDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.DELETE_DEVICE, {})
      }
    })
  }

  saveCreateDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.SAVE_CREATE_DEVICE, {})
      }
    })
  }

  saveEditDevice() {
    this.userInformation$.subscribe((user) => {
      if (user) {
        this.analyticsService.pushEvent(this.EVENTS.SAVE_EDIT_DEVICE, {})
      }
    })
  }

  login () {
    this.analyticsService.pushEvent(this.EVENTS.LOGIN, {});
  }
}
