import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { SiteService } from '../../shared/services/sites.service';
import { DeviceService } from '../../shared/services/devices.service';

export interface DeviceData {
  deviceId: string;
}

@Component({
  selector: 'app-delete-dialog',
  templateUrl: './delete-dialog.component.html',
  styleUrls: ['./delete-dialog.component.scss'],
})
export class DeleteDialogComponent {
  dialogState = 'DEFAULT';

  constructor(
    private router: Router,
    public dialogRef: MatDialogRef<DeleteDialogComponent>,
    private deviceService: DeviceService,
    private siteService: SiteService,
    @Inject(MAT_DIALOG_DATA) public data: DeviceData,
  ) {}

  onNoClick(): void {
    this.dialogRef.close();
  }

  async handleDelete() {
    this.dialogRef.disableClose = true;
    try {
      this.dialogState = 'DELETING';
      await this.deviceService.deleteDevice(this.data.deviceId);
      this.dialogState = 'DELETE_SUCCESS';
      this.deviceService.refetchDevices();
      this.siteService.getSites();
      setTimeout(() => {
        this.dialogRef.close();
        this.router.navigate([`/`], {});
      }, 2000);
    } catch (e) {
      this.dialogState = 'DELETE_FAILED';
      setTimeout(() => {
        this.dialogRef.close();
      }, 3000);
    }
  }
}
