import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { Channel } from '../model/channel.model';

@Injectable()
export class ChannelsService {
  channels$: BehaviorSubject<Channel[]>;

  constructor(private apiService: ApiService) {
    this.channels$ = new BehaviorSubject<Channel[]>([]);
  }

  async setChannels() {
    const channels = await this.apiService.get('channels');
    this.channels$.next(convertKeys.toCamel(channels));
  }
}
