import { Environment } from './environment.interface';
export { Environment } from './environment.interface';

export const environment: Environment = {
  production: false,
  brands: {
    enelx: {
      baseUrl: '/',
    },
  },
  defaults: {
    brand: 'enelx',
    locale: 'en-us',
  },
  dataprovider_id: {
    ENEL_IOT_FLEXIBILITY : '3480cc3a-f1e2-4efc-b4c8-c8f9f4207068',
  },
};
