export interface FilterTag {
  id: string;
  period: string;
  tag: string;
}

export interface ThingParameters {
  thing_id: string;
  device_type: string;
  radio_type: string;
  model: string;
  maker: string;
  serial_number: string;
  environment_prefix: string;
  interaction_mode: string;
  authentication_mode: string;
  outbound_communication_modes: string[];
  inbound_communication_mode: string[];
  filter_tag: FilterTag[];
}

export interface Thing {
  id: string;
  command_name: string;
  device_id: string;
  status: string;
  parameters: ThingParameters;
}
