import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import { GatewayType } from '../model/gateway-type.model';

@Injectable()
export class GatewayTypesService {
  gatewayTypes$: BehaviorSubject<GatewayType[]>;

  constructor(private apiService: ApiService) {
    this.gatewayTypes$ = new BehaviorSubject<GatewayType[]>([]);
  }

  async setGatewayTypes() {
    const gatewayTypes = await this.apiService.get('gateway_types');
    this.gatewayTypes$.next(convertKeys.toCamel(gatewayTypes));
  }
}
