<div class="app-device-edit content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>edit</mat-icon>
                </div>
                <h1 class="ellipsis" data-automation="edit-title">
                    {{ device?.displayLabel }}
                </h1>
                <p class="desktop-only subtitle" data-automation="subtitle">
                    {{ 'device.edit.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="gray"
                (click)="handleCancel()"
                [disabled]="this.deviceId === ''"
                data-automation="deviceCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'device.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="this.deviceId === ''"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'device.button.delete' | translate }}</span>
            </button>
            <button
                class="icon-btn x-btn-primary"
                color="accent"
                (click)="handleSubmit()"
                data-automation="deviceSave"
                [disabled]="!deviceForm.valid"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'device.button.save' | translate }}</span>
            </button>
        </div>
    </header>

    <form #edit="ngForm" class="device-form">
        <app-device-form [mode]="mode" [deviceForm]="deviceForm"></app-device-form>
    </form>
</div>
