import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { Meter } from '../model/meter.model';

@Injectable({
  providedIn: 'root',
})
export class MeterService {
  constructor(private apiService: ApiService) {}

  async createMeter(meter: Meter): Promise<void> {
    const createdMeter = await this.apiService.post('meter', meter);
    return convertKeys.toCamel(createdMeter);
  }

  async deleteMeter(meterId: string): Promise<void> {
    return await this.apiService.delete(`meter/${meterId}`);
  }
}
