<div class="app-device-create content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon>library_add</mat-icon>
                </div>
                <h1 id="create-title" data-automation="pageTitle">{{ 'device.create.page_title' | translate }}</h1>
                <p class="subtitle ellipsis" data-automation="subtitle">
                    {{ 'device.create.subtitle' | translate }}
                </p>
            </div>
        </div>

        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                aria-label="Cancel"
                (click)="handleCancel()"
                data-automation="deviceCancel"
            >
                <mat-icon>clear</mat-icon>
                <span class="btn-text"> {{ 'device.button.cancel' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                aria-label="Submit"
                (click)="handleSubmit()"
                [disabled]="!deviceForm.valid"
                type="submit"
                data-automation="deviceSubmit"
            >
                <mat-icon *ngIf="!isSubmitting">done</mat-icon>
                <div *ngIf="isSubmitting" class="btn-spinner">
                    <mat-spinner diameter="15"></mat-spinner>
                </div>
                <span class="btn-text"> {{ 'device.button.submit' | translate }}</span>
            </button>
        </div>
    </header>

    <form #createForm="ngForm" data-app-prefix="prt" class="device-form">
        <app-device-form [mode]="mode" [deviceForm]="deviceForm"></app-device-form>
    </form>
</div>
