import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { User } from '../model/user.model';
import { ApiService } from './api.service';
import { Permission } from './auth-guard.service';

const appName = 'EQUIPMENT_MANAGEMENT';

@Injectable()
export class UserService {
  redirectUrl: string;
  private user: User;
  user$ = new BehaviorSubject<User>(null);

  readonly appPermissions$: BehaviorSubject<Permission[]> = new BehaviorSubject<Permission[]>(null);
  readonly permissions$: BehaviorSubject<[]> = new BehaviorSubject<[]>([]);

  constructor(private apiService: ApiService) {
    this.getPermissions();
  }

  /**
   * Get's a user from the backend and stores it in cache for reuse
   *
   * @returns {Promise<User>}
   */
  async getUser() {
    if (!this.user) {
      try {
        let dataUser = await this.apiService.get('user');
        let user = new User();
        this.user.firstName = dataUser.first_name;
        this.user.lastSystemName = dataUser.last_system_name;
        this.user.userType = dataUser.user_type;
        this.user.userClass = dataUser.user_class;
        this.user.email = dataUser.email;
        this.user.phone = dataUser.phone;
        this.user.countryCode = dataUser.country;
        this.user.username = dataUser.username;
        this.user.userId = dataUser.user_id;
        this.user.brand = dataUser.brand;
        this.user.locale = dataUser.locale;
        this.user.primaryOrg = dataUser.primary_org;
        this.user$.next(this.user);
      } catch (err) {
        console.log('Error: UserService - could not fetch user');
        console.log(JSON.stringify(err));
      }
    }
  }

  /**
   * Get the Permissions for the Markets app that the logged in User has.
   *
   * @returns {Promise<User>}
   */
  async getAppPermissions() {
    if (this.appPermissions$.getValue()) {
      return this.appPermissions$.getValue();
    }
    const perms = await this.apiService.get('user/permissions');
    const permissions = perms && perms[appName] ? perms[appName] : [];
    this.appPermissions$.next(permissions);
    return permissions;
  }

  async getPermissions() {
    const permissions = await this.apiService.get('user/permissions');
    this.permissions$.next(permissions);
  }

  hasPermission(resource: string, permission: string) {
    const permissions = this.permissions$.getValue();
    return !!(permissions[resource] && permissions[resource].includes(permission));
  }
}
