import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { AtlasPagesService } from 'atlas-pages-loader';

const EQUIPMENT_HIERARCHY = ['equipment'];

@Injectable()
export class EquipmentService {
    equipment$ = new BehaviorSubject<any[]>([]);
    loadingEquipment$ = new BehaviorSubject<boolean>(false);
    atlasPageLoader: AtlasPagesService;
    cookieService: CookieService;
    constructor(
        private httpClient: HttpClient,
        cookieService: CookieService,
    ) {
        this.cookieService = cookieService;
        this.initPageLoader();
    }

    initPageLoader() {
        this.atlasPageLoader = new AtlasPagesService(
            this.cookieService.getMarketsMock(),
            this.cookieService.getEnocSession(),
            this.httpClient,
        );
    }

    async getEquipment(siteId: string) {
        this.loadingEquipment$.next(true);
        this.initPageLoader();
        const FilterBy = { id: siteId, dr_type: 'SITE' };
        const siteEquipment = await this.atlasPageLoader.getSpecificHierarchies(EQUIPMENT_HIERARCHY, FilterBy);
        this.equipment$.next((convertKeys.toCamel({ siteEquipment }) as any).siteEquipment);
        this.loadingEquipment$.next(false);
    }
}
