import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { MeterService } from '../../shared/services/meter.service';
import { MeasuredPointsService } from '../../shared/services/measured-points.service';
import { DeviceService } from '../../shared/services/devices.service';
import {
  DETAILS,
  EntityBulkCreationData,
  MESSAGE,
  ReportMessage,
  STATE,
  STATE_ICON,
  STEP,
  VIEWS,
} from './entity-bulk-creation.models';

@Component({
  selector: 'app-auto-create',
  templateUrl: './entity-bulk-creation.component.html',
  styleUrls: ['./entity-bulk-creation.component.scss'],
})
export class EntityBulkCreationComponent implements OnInit {
  dialogState = 'CONFIRM';
  autoCreateStep = '';
  meter;
  measuredPoints;
  device;
  creating = false;

  reportMessages: Array<ReportMessage> = [];

  constructor(
    public dialogRef: MatDialogRef<EntityBulkCreationComponent>,
    private router: Router,
    private meterService: MeterService,
    private measuredPointsService: MeasuredPointsService,
    private deviceService: DeviceService,
    @Inject(MAT_DIALOG_DATA) public data: EntityBulkCreationData,
  ) {}

  async ngOnInit(): Promise<void> {
    this.reportMessages = [];
    this.dialogRef.disableClose = true;
    this.dialogState = VIEWS.confirm;
  }

  onCancel() {
    this.dialogRef.close();
  }

  async runAutoCreate() {
    this.dialogState = VIEWS.report;
    this.creating = true;
    this.autoCreateStep = STEP.meter;
    const meterCreated = await this.createMeter();
    if (meterCreated) {
      this.autoCreateStep = STEP.measuredPoints;
      const measuredPointsCreated = await this.createMeasuredPoints();
      if (measuredPointsCreated) {
        this.autoCreateStep = STEP.device;
        await this.createDevice();
      }
    }
    this.creating = false;
  }

  async createMeter(): Promise<any> {
    try {
      const meter = this.data.mapDataToMeter();
      this.meter = await this.meterService.createMeter(meter);
      this.writeReport(STEP.meter, STATE.success, MESSAGE.success, STATE_ICON.success);
      return true;
    } catch (e) {
      console.log('Meter creation failed', e);
      const error_message = e.error.message || e.error.details;
      this.writeReport(STEP.meter, STATE.fail, MESSAGE.failed, STATE_ICON.error, DETAILS.meterFailed, [
        { error_message },
      ]);
      return false;
    }
  }

  async createMeasuredPoints(): Promise<boolean> {
    const pointsToCreate = this.data.thing.parameters.filter_tag.length;
    if (pointsToCreate > 0) {
      const pointResponse = this.data.mapDataToMeasuredPoints(this.meter.id);
      const newPoints = await this.measuredPointsService.createMeasuredPoints(pointResponse.points);
      this.measuredPoints = newPoints;
      const details = {
        pointsCreated: newPoints.measured_points_created,
        pointsFailed: newPoints.measured_points_failed,
      };
      if (newPoints.measured_points_created === 0) {
        this.writeReport(
          STEP.measuredPoints,
          STATE.fail,
          MESSAGE.failed,
          STATE_ICON.error,
          DETAILS.pointsFailed,
          newPoints.errors,
          details,
        );
        try {
          this.autoCreateStep = STEP.undoMeter;
          await this.meterService.deleteMeter(this.meter.id);
          this.writeReport(STEP.undoMeter, STATE.partial, MESSAGE.rollback, STATE_ICON.rollback, DETAILS.meterRollback);
        } catch (error) {
          this.writeReport(
            STEP.undoMeter,
            STATE.fail,
            MESSAGE.rollbackFail,
            STATE_ICON.error,
            DETAILS.meterRollbackFailed,
          );
        }
        return false;
      }

      if (newPoints.errors.length > 0 && newPoints.measured_points_created > 0) {
        if (pointResponse.errors.length > 0) {
          this.writeReport(
            STEP.measuredPoints,
            STATE.partial,
            MESSAGE.partial,
            STATE_ICON.warning,
            DETAILS.invalidPeriod,
            pointResponse.errors.concat(newPoints.errors),
            details,
          );
        }
        else {
          this.writeReport(
            STEP.measuredPoints,
            STATE.partial,
            MESSAGE.partial,
            STATE_ICON.warning,
            null,
            newPoints.errors,
            details,
          );
        }
        return true;
      }

      if (newPoints.measured_points_failed === 0) {
        if (pointResponse.errors.length > 0) {
          this.writeReport(
            STEP.measuredPoints,
            STATE.partial,
            MESSAGE.success,
            STATE_ICON.success,
            DETAILS.invalidPeriod,
            pointResponse.errors,
          );
        } else {
          this.writeReport(STEP.measuredPoints, STATE.success, MESSAGE.success, STATE_ICON.success);
        }
        return true;
      }
    } else {
      console.log('No measured points to create');
      this.writeReport(STEP.measuredPoints, STATE.partial, MESSAGE.noPoints, STATE_ICON.warning);
      return true;
    }
  }

  async createDevice() {
    try {
      const newDevice = this.data.mapDataToDevice(this.meter.id);
      const device = await this.deviceService.createDevice(newDevice);
      this.device = device;
      this.writeReport(STEP.device, STATE.success, MESSAGE.success, STATE_ICON.success);
    } catch (e) {
      this.writeReport(STEP.device, STATE.fail, MESSAGE.failed, STATE_ICON.error, DETAILS.deviceFailed);
    }
  }

  onOkReport() {
    if (this.device) {
      this.redirectToViewDevice();
    }
    this.dialogRef.close();
  }

  private redirectToViewDevice() {
    this.deviceService.refetchDevices();
    this.router.navigate([`/details/${this.device.id}/view`]);
  }

  writeReport(step, state, message, icon, details?, errors?, info?) {
    this.reportMessages.push({ step, state, message, details, icon, errors, info });
  }
}
