import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { DeleteDialogComponent } from '../dialogs/delete/delete-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { DeviceService } from '../shared/services/devices.service';
import { TimezonesService } from '../shared/services/timezones.service';
import { Timezone, Device, Channel, GatewayType, Status } from '@model';
import { GlobalAlertService } from '../shared/services/global-alert.service';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { GatewayTypesService } from '../shared/services/gateway.service';
import { ChannelsService } from '../shared/services/channels.service';
import { mappedTypes, statuses } from '../shared/model/device';
import { OrganizationsService } from '../shared/services/organizations.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { DEFAULT_DEVICE } from '../shared/model/device';
import { ContextSelectorService } from 'ngx-global-nav';
import { MixPanelService } from '../shared/services/mixpanel.service';

@Component({
  selector: 'app-view',
  templateUrl: './view.component.html',
  styleUrls: ['./view.component.scss', '../shared/shared.styles.scss'],
})
export class ViewComponent implements OnInit, OnDestroy {
  deviceId = '';
  subscriptions = [];
  timezones: Timezone[];
  labels: Device[];
  devicesList: Device[] = [];
  equipmentList = [];
  intervalDataList = [];
  loadingDevice: boolean = false;
  userLocale;
  equipments = [];

  device: Device = DEFAULT_DEVICE;
  readonly mappedTypes = mappedTypes;
  readonly statuses = statuses;
  channels: Channel[];
  gatewayTypes: GatewayType[];
  CONFLICT = 'There is a data conflict with this Device';
  readonly mode = 'view';
  hasControlSets: boolean;
  hasMeters: boolean;

  hasMetadata: boolean = false;
  currentOrg: string = '';

  equipmentBaseUrl: string;

  constructor(
    private timezonesService: TimezonesService,
    private deviceService: DeviceService,
    private router: Router,
    private messageService: GlobalAlertService,
    private translateService: TranslateService,
    private route: ActivatedRoute,
    private ngxCookieService: NgxCookieService,
    private gatewayService: GatewayTypesService,
    private channelService: ChannelsService,
    private organizationsService: OrganizationsService,
    public dialog: MatDialog,
    private contextSelectorService: ContextSelectorService,
    private mixpanelService: MixPanelService,
  ) {
    this.translateService.get('device.notification.updated_successfully').subscribe((result: string) => {
      this.CONFLICT = this.translateService.instant('device.notification.conflict');
    });

    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';

/*    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }*/

    const routeSub = this.route.params.subscribe(async (params) => {
      if (params.id) {
        this.deviceId = params.id;
        this.deviceService.getDevice(this.deviceId);
      }
    });

    const channelsSub = this.channelService.channels$.subscribe((channels) => {
      this.channels = channels;
    });

    const gatewayTypesSub = this.gatewayService.gatewayTypes$.subscribe((gatewayTypes) => {
      this.gatewayTypes = gatewayTypes;
    });

    const devicesSub = this.deviceService.devices$.subscribe(async (devices) => {
      this.devicesList = devices;
      if (devices && devices.length === 0) {
        await this.router.navigate(['']);
      }
    });

    const conflictErrorSub = this.deviceService.conflictError.subscribe(() => {
      this.messageService.setError(this.CONFLICT, 7000);
    });

    const loadingSub = this.deviceService.getLoadingDevice().subscribe((loading) => {
      this.loadingDevice = loading;
    });

    const deviceSub = this.deviceService.device$.subscribe((device) => {
      if (device) {
        this.device = device;
        // await this.deviceService.getOrgDevices(device.site.organizationId);
        this.hasControlSets = this.device.controlSets.length > 0;
        this.hasMeters = this.device.meters.length > 0;
        this.hasMetadata = this.device.lastSrMetadata != null;
        this.device.meters.forEach((equipment: any) => {
          equipment['type_equipment'] = 'meters';
        });
        this.device.controlSets.forEach((equipment: any) => {
          equipment['type_equipment'] = 'controlSets';
        });

        this.equipments = [...this.device.meters, ...this.device.controlSets];
        this.organizationsService.getOrgs(device.site.organizationId);
      }
    });

    const contextSub = this.contextSelectorService.currentContext$.subscribe(async (org) => {
      this.currentOrg = org[0].id;
    });

    const equipmentBaseUrlSub = this.deviceService.equipmentBaseUrl$.subscribe(url => {
      if(url) {
        this.equipmentBaseUrl = url;
      }
    });

    this.loadDynamicLists();
    this.deviceService.fetchEquipmentBaseUrl();

    this.subscriptions = [routeSub, deviceSub, loadingSub, channelsSub, conflictErrorSub, devicesSub, gatewayTypesSub, contextSub, equipmentBaseUrlSub];
  }

  ngOnInit() {}

  getStatusForDevice() {
    const deviceStatus = this.statuses.find((status) => status.name === this.device.status);
    return deviceStatus.displayLabel || '-';
  }

  getSiteForDevice() {
    if (this.device && this.device.site) {
      return this.device.site.displayLabel || '-';
    }
  }

  getGatewayType(deviceGatewayType) {
    if (this.gatewayTypes.length > 0 && this.device && deviceGatewayType) {
      const gatewayType = this.gatewayTypes.find((gatewayType) => gatewayType.code === deviceGatewayType);
      return gatewayType && gatewayType.displayLabel ? gatewayType.displayLabel : deviceGatewayType;
    }
    return deviceGatewayType || '-';
  }

  getChannelLabel(deviceChannel) {
    if (this.channels.length > 0 && this.device && deviceChannel) {
      const channelFound = this.channels.find((channel) => channel.id === deviceChannel.key);
      return channelFound.displayLabel || '-';
    }
    return '-';
  }

  adequateQuality(strength) {
    if (!strength) {
      return false;
    }
    if (strength <= -90) {
      return 'device.quality_values.poor';
    } else {
      return 'device.quality_values.good';
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  handleCancel() {
    this.router.navigate(['list']);
  }

  openDeleteDialog(): void {
    this.mixpanelService.deleteDevice();
    this.dialog.open(DeleteDialogComponent, {
      width: '400px',
      data: {
        deviceId: this.deviceId,
      },
    });
  }

  handleEdit() {
    this.mixpanelService.editDevice();
    this.router.navigate([`details/${this.deviceId}/edit`]);
  }

  private async loadDynamicLists() {
    this.timezonesService.setTimezones();
    this.channelService.setChannels();
    this.gatewayService.setGatewayTypes();
  }
  buildEquipmentUrl(id: string): string {
    return `${this.equipmentBaseUrl}/details/${id}/view`;
  }
}
