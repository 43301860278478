import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { CookieService } from '../shared/services/cookie.service';

@Injectable()
export class MockHttpInterceptor implements HttpInterceptor {
  constructor(private cookieService: CookieService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.cookieService.getCookie('mock_mode')) {
      const mockResp = sessionStorage.getItem(`${request.method} ${request.url}`);
      const resp: HttpResponse<any> = new HttpResponse({});

      if (mockResp) {
        const parsedResponse = JSON.parse(mockResp);
        if (parsedResponse.code >= 400) {
          const errorResp = new HttpErrorResponse({
            status: parsedResponse.code,
            error: {
              message: parsedResponse.message,
            },
          });
          return throwError(errorResp);
        }
        const response = resp.clone({
          body: parsedResponse,
          status: 200,
        });

        return of(response);
      } else {
        console.log(`Missing Mock Data for "${request.method} ${request.url}"`);

        const missingMocks: any[] = (JSON.parse(sessionStorage.getItem('MISSING_MOCKS')) as any[]) || [];
        missingMocks.push({
          method: request.method,
          url: request.url,
        });
        sessionStorage.setItem('MISSING_MOCKS', JSON.stringify(missingMocks));

        const response = resp.clone({
          body: JSON.parse(mockResp),
          status: 400,
        });
        return of(response);
      }
    } else {
      return next.handle(request);
    }
  }
}
