import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { SidenavService } from '../shared/services/sidenav.service';
import { DeviceService } from '../shared/services/devices.service';
import { Context, ContextSelectorService } from 'ngx-global-nav';
import {MixPanelService} from '../shared/services/mixpanel.service';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss'],
})
export class ListComponent {
  displayedColumns: string[] = ['status', 'display_label'];
  orgId: string;
  loading = true;
  loadingOrg = true;
  noDevices = false;
  dataSource: any[];
  selectedDeviceId = '';

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private sidenavService: SidenavService,
    private orgSelectorService: ContextSelectorService,
    private mixpanelService: MixPanelService,
  ) {
    this.deviceService.getLoadingDevices().subscribe(loading => {
      this.loading = loading;
    });

    this.orgSelectorService.currentContext$.subscribe(async (orgs: Context[]) => {
      const org = orgs[0];
      if (org && org.id) {
        this.loadingOrg = false;
        this.orgId = org.id;
        await this.deviceService.getOrgDevices(this.orgId);
      }
    });

    this.deviceService.selectedDeviceId$.subscribe(async deviceId => {
      if (deviceId) {
        this.selectedDeviceId = deviceId;
      }
    });

    this.deviceService.devices$.subscribe(async devices => {
      if (devices) {
        this.dataSource = devices;
        this.noDevices = devices.length === 0;
      }
    });
  }

  onRowClicked(id) {
    this.mixpanelService.viewDevice();
    this.sidenavService.closeIfMobile();
    this.router.navigate([`details/${id}/view`], {});
  }

  routeToCreatePage() {
    this.mixpanelService.createDevice();
    this.sidenavService.closeIfMobile();
    this.router.navigate([`create`], {});
  }
}
