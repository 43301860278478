<table *ngIf="!loading" mat-table [dataSource]="dataSource" [ngClass]="{ stickyTableHeader: !noDevices || !loading }">
    <!-- Name Column -->
    <ng-container matColumnDef="display_label">
        <th mat-header-cell *matHeaderCellDef>{{ 'device.list.table.heading.name' | translate }}</th>
        <td mat-cell *matCellDef="let element">
            <div
                class="device-label ellipsis"
                title="{{ element.displayLabel || element.id }}"
                data-automation="deviceTitle"
            >
                {{ element.displayLabel || element.id }}
            </div>
        </td>
    </ng-container>

    <!--  Status Column -->
    <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element">
            <ng-container [ngSwitch]="element.status">
                <div
                    *ngSwitchCase="'ACTIVE'"
                    class="status-icon status-icon-active"
                    title="Active"
                    data-automation="statusIndicator"
                ></div>
                <div
                    *ngSwitchCase="'INACTIVE'"
                    class="status-icon status-icon-inactive"
                    title="Inactive"
                    data-automation="statusIndicator"
                ></div>
                <div
                    *ngSwitchCase="'DECOMMISSIONED'"
                    class="status-icon status-icon-decommissioned"
                    title="Decommissioned"
                    data-automation="statusIndicator"
                ></div>
                <div
                    *ngSwitchCase="'ORPHANED'"
                    class="status-icon status-icon-orphaned"
                    title="Orphaned"
                    data-automation="statusIndicator"
                ></div>
            </ng-container>
        </td>
    </ng-container>

    <tr
        mat-row
        *matRowDef="let row; let isEven = even; columns: displayedColumns"
        class="device-list-row"
        [ngClass]="{ selectedDevice: row.id === selectedDeviceId }"
        (click)="onRowClicked(row.id)"
    ></tr>
</table>

<ng-container *ngIf="!loading">
    <div *ngIf="noDevices" class="list-empty">
        <span data-automation="emptyMessage">{{ 'device.list.none_configured' | translate }}</span>
    </div>
</ng-container>
<div class="spinner-container" *ngIf="loading || loadingOrg">
    <mat-spinner mode="indeterminate" diameter="60"></mat-spinner>
</div>
<div class="create-button-container">
    <button
        (click)="routeToCreatePage()"
        id="create-button"
        class="icon-btn x-btn-primary"
        color="accent"
        data-automation="createBtn"
    >
        <mat-icon class="add-icon">add</mat-icon>
        <span class="btn-text">{{ 'device.list.button.create_device' | translate }}</span>
    </button>
</div>
