<ng-container *ngIf="dialogState === 'CONFIRM'">
    <h1 mat-dialog-title>{{ 'device.dialog.auto_create.title' | translate }}</h1>
    <div class="separator"></div>
    <div mat-dialog-content>
        <p>
            {{ 'device.dialog.auto_create.text' | translate }}
        </p>
    </div>
    <div mat-dialog-actions>
        <button
            class="x-btn-secondary dialog-secondary"
            data-automation="BulkCreateCancel"
            aria-label="Cancel"
            (click)="onCancel()"
        >
            <span> {{ 'device.button.cancel' | translate }}</span>
        </button>

        <button
            class="x-btn-primary dialog-primary"
            color="accent"
            data-automation="BulkCreateConfirm"
            aria-label="Confirm"
            (click)="runAutoCreate()"
        >
            <span> {{ 'device.button.start' | translate }}</span>
        </button>
    </div>
</ng-container>

<ng-container *ngIf="dialogState === 'REPORT'">
    <div mat-dialog-content>
        <div *ngIf="!creating" class="dialog-result">
            <span data-automation="reportTitle">{{ 'device.dialog.auto_create.report.title' | translate }}</span>
        </div>
        <div *ngIf="creating" class="dialog-result">
            <span>{{ 'device.dialog.auto_create.creating' | translate }}</span>
        </div>
    </div>
    <div class="separator-20"></div>

    <ng-container *ngFor="let reportItem of reportMessages">
        <mat-expansion-panel
            [ngClass]="'item-' + reportItem.state.toLowerCase()"
            [disabled]="!(reportItem.details || (reportItem.errors && reportItem.errors.length > 0) || reportItem.info)"
        >
            <mat-expansion-panel-header class="report-item-header">
                <mat-panel-title id="{{ reportItem.step + '_item_report' }}"
                    ><mat-icon class="icon-report">{{ reportItem.icon }}</mat-icon>
                    <b>{{ 'device.dialog.auto_create.report.' + reportItem.step | translate }}</b>
                    <pre [id]="reportItem.step + '_item_message'">
: {{ 'device.dialog.auto_create.report.' + reportItem.message | translate }}</pre
                    >
                </mat-panel-title>
            </mat-expansion-panel-header>
            <ng-container *ngIf="reportItem.details">
                <span [id]="reportItem.step + '_item_details'">
                    {{ 'device.dialog.auto_create.report.details.' + reportItem.details | translate }}
                </span>
                <br />
            </ng-container>
            <ng-container *ngIf="reportItem.info">
                <div [id]="reportItem.step + '_item_info'">
                    <span
                        ><b>{{ 'device.dialog.auto_create.measured_points_ok' | translate }}</b
                        >{{ reportItem.info.pointsCreated }}</span
                    ><br />
                    <span
                        ><b>{{ 'device.dialog.auto_create.measured_points_fail' | translate }}</b
                        >{{ reportItem.info.pointsFailed }}</span
                    ><br />
                </div>
            </ng-container>
            <div class="separator-20"></div>
            <ng-container *ngIf="reportItem.errors && reportItem.errors.length > 0">
                <span
                    ><b>{{ 'device.dialog.auto_create.errors' | translate }}</b></span
                >
                <mat-list [id]="reportItem.step + '_item_errors'">
                    <mat-list-item *ngFor="let error of reportItem.errors; let i = index" class="auto-height">
                        <div matListItemLine>
                            <b>{{ i + 1 + ' - ' }}</b
                            >{{ error.error_message + (error.fields && ' - ' + error.fields) }}
                        </div>
                    </mat-list-item>
                </mat-list>
            </ng-container>
        </mat-expansion-panel>
        <div class="separator-5"></div>
    </ng-container>
    <mat-expansion-panel *ngIf="creating" disabled>
        <mat-expansion-panel-header class="report-item-header">
            <mat-panel-title
                ><mat-spinner diameter="24"></mat-spinner>
                <b>{{ 'device.dialog.auto_create.creating.' + autoCreateStep | translate }}</b>
            </mat-panel-title>
        </mat-expansion-panel-header>
    </mat-expansion-panel>
    <div mat-dialog-actions *ngIf="!creating">
        <button class="x-btn-primary dialog-primary" aria-label="Cancel" (click)="onOkReport()">
            <span> {{ 'device.button.ok' | translate }}</span>
        </button>
    </div>
</ng-container>
