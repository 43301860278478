import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject, Observable } from 'rxjs';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { ResourceService } from '../../services/resource.service';
import { Device } from '@model';
import { finalize, map, tap } from 'rxjs/operators';
import { PostDevice } from '../model/device';
@Injectable()
export class DeviceService {
  conflictError = new Subject<any[]>();
  readonly devices$ = new BehaviorSubject<Device[]>(null);
  readonly device$ = new BehaviorSubject<Device>(null);
  readonly selectedDeviceId$ = new BehaviorSubject<string>(null);
  readonly selectedDevice$ = new BehaviorSubject<Device>(null);
  private readonly _loadingDevices$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private readonly _loadingDevice$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  private currentOrgId: string;
  activationData$ = new BehaviorSubject<any>(null);
  readonly signalData$ = new BehaviorSubject<any>(null);
  readonly equipmentBaseUrl$ = new BehaviorSubject<string>(null);

  readonly thingRegistrations$ = new BehaviorSubject<any>(null);

  constructor(private apiService: ApiService, private resourceService: ResourceService<Device>) {}

  getDeviceObservable() {
    return this.device$.asObservable();
  }

  getLoadingDevice() {
    return this._loadingDevice$.asObservable();
  }

  getLoadingDevices() {
    return this._loadingDevices$.asObservable();
  }

  async getOrgDevices(orgId: string) {
    try {
      this.currentOrgId = orgId;
      this._loadingDevices$.next(true);
      this.resourceService
        .getPaginated(`organizations/${orgId}/devices`)
        .pipe(finalize(() => this._loadingDevices$.next(false)))
        .subscribe((res) => {
          const sorted = res.sort((a, b) => a.displayLabel.localeCompare(b.displayLabel));
          this.devices$.next(sorted);
        });
    } catch (err) {
      console.log('Could not load devices.', err);
    }
  }
  getDevice(id: string) {
    this._loadingDevice$.next(true);
    const device = this.resourceService.get(`devices/${id}`).pipe(
      tap(() => this._loadingDevice$.next(false)),
      map((device) => {
        return {
          ...device,
          equipmentIds: [
            ...device['meters'].map((meter: { id: any }) => meter.id),
            ...device['controlSets'].map((controlSet: { id: any }) => controlSet.id),
          ],
        };
      }),
    );
    device.subscribe((device) => {
      this.selectedDeviceId$.next(id);
      this.device$.next(device);
    });
    return device;
  }

  selectDevice(deviceId: string) {
    if (this.devices$.value && this.devices$.value.length > 0) {
      const device = this.devices$.value.find((device) => {
        return device.id === deviceId;
      });
      this.selectedDevice$.next(device);
    }
  }

  async refetchDevices() {
    if (this.currentOrgId) {
      await this.getOrgDevices(this.currentOrgId);
    }
  }

  async refetchDevice() {
    if (this.selectedDevice$.value) {
      await this.getDevice(this.selectedDevice$.value.id);
    }
  }

  async createDevice(device: PostDevice) {
    try {
      const dto = convertKeys.toSnake<any>(device);
      dto.display_labels = device.displayLabels;
      const createdDevice = await this.apiService.post('devices', dto);
      return convertKeys.toCamel<Device>(createdDevice);
    } catch (err) {
      console.log(`Could not Create Device`, err);
      throw err;
    }
  }

  async deleteDevice(deviceId: string) {
    const deletedDevice = await this.apiService.delete(`devices/${deviceId}`);
    return convertKeys.toCamel<any>(deletedDevice);
  }

  async updateDevice(device: Device) {
    try {
      const id = device.id;
      const dto = convertKeys.toSnake<any>(device);
      dto.display_labels = device.displayLabels;
      const updatedDevice = await this.apiService.put(`devices/${id}`, dto);
      return convertKeys.toCamel<any>(updatedDevice);
    } catch (err) {
      console.log(`Could not Update Device`, err);
      throw err;
    }
  }
  async getGatewayActivationData(gatewayId) {
    try {
      const response = await this.apiService.get(`gateway/${gatewayId}`);
      const activationData = await this.apiService.get(`gateway/${gatewayId}/activation-payload`);
      this.activationData$.next({ devices: response, activation: activationData });
    } catch (error) {
      this.activationData$.next(error);
    }
  }

  async getThingRegistration(thingId) {
    try {
      const response = await this.apiService.get(`thing-registrations?search_string=${thingId}`);
      this.thingRegistrations$.next(response);
    } catch (error) {
      this.thingRegistrations$.next(error);
    }
  }

  async clearThingRegistrations() {
    this.thingRegistrations$.next([]);
  }

  async fetchCurrentSignalStrength(getwayId) {
    try {
      const signalData = await this.apiService.get(`gateway/${getwayId}/signal-strength`);
      this.signalData$.next(signalData);
    } catch (error) {
      this.signalData$.next(error);
    }
  }

  async fetchEquipmentBaseUrl() {
    try {
      const url = await this.apiService.get('deeplinks/equipment');
      this.equipmentBaseUrl$.next(url);
    } catch (e) {
      console.log('Could not get Equipment URL');
      throw e;
    }
  }
}
