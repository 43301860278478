import { Inject, Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpParams } from '@angular/common/http';
import {from, lastValueFrom, Observable, throwError} from 'rxjs';
import {catchError, map } from 'rxjs/operators';
import { CookieService } from '../shared/services/cookie.service';
import * as convertKeys from 'convert-keys';
import { DOCUMENT } from '@angular/common';

@Injectable({
  providedIn: 'root',
})
export class ResourceService<T> {
  private apiLocation: string;
  private loginEndpoint = 'links/login';
  private forbiddenPath = '/forbidden/index.html';

  private PAGE_SIZE = 100;

  constructor(
    private httpClient: HttpClient,
    private cookieService: CookieService,
    @Inject(DOCUMENT) private document: any,
  ) {
    this.apiLocation = this.cookieService.getMarketsMock() ? 'http://wiremock:8888/v1' : '/api/v1';
  }

  private redirectToLogin() {
    const prezEndpoint = this.loginEndpoint;
    console.log('redirectToLogin - redirecting to endpoint: ', prezEndpoint);
    if (prezEndpoint) {
      return this.httpClient
        .get<T>(`${this.apiLocation}/${prezEndpoint}`)
        .pipe(
          map(item => item['data']),
          catchError(err => {
            console.error('redirect threw error', err);
            return throwError(`Error ${err.status}. ${err.message}`);
          }),
        )
        .subscribe(links => {
          console.log('found links for user: ', links);
          const loginPage = links.LOGIN_PAGE ? links.LOGIN_PAGE.reference : links.reference;
          this.document.location = loginPage + '&goto=' + this.document.location;
        });
    } else {
      console.log('tried to redirect with a null endpoint');
    }
  }

  private redirectToForbidden() {
    const redirectLocation = document.location.origin + this.forbiddenPath + '?type=forbidden';
    console.log('Redirecting to ', redirectLocation);
    this.document.location = redirectLocation;
  }

  private handleError(error: HttpErrorResponse): Observable<never> {
    if (error.status === 401) {
      this.redirectToLogin();
    } else if (error.status === 403) {
      this.redirectToForbidden();
    } else {
      console.log('Error: API Service - ' + JSON.stringify(error.error));
      return throwError(`Error ${error.status}. ${error.message}`);
    }
  }

  get(path: string, params?: HttpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}`, { params })
      .pipe(
        map(item => {
          const convertedItem =  convertKeys.toCamel<T>(item['data']); 
          return {...convertedItem, displayLabels: item['data'].display_labels}
        }),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }

  getById(path: string, id: string | number, params?: HttpParams): Observable<T> {
    return this.httpClient
      .get<T>(`${this.apiLocation}/${path}/${id}`, { params })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }

  getList(path: string, params?: HttpParams): Observable<T[]> {
    return this.httpClient
      .get<T[]>(`${this.apiLocation}/${path}`, { params })
      .pipe(
        map(items => items['data'].map(p => convertKeys.toCamel<T>(p))),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }

  getPaginated(path: string, params?: HttpParams): Observable<T[]> {
      return from(this.paginationHelper(path, 1, [], params));
  }

  async paginationHelper(path: string, page: number, data: any[], params?: HttpParams): Promise<any[]> {
      const pageData$ = this.getList(`${path}?page=${page}&page_size=${this.PAGE_SIZE}`, params);
      const pageData = await lastValueFrom(pageData$);
      if (pageData && pageData.length) {
          data.push(...pageData);
          if (pageData.length < this.PAGE_SIZE) {
              return data;
          }
          return await this.paginationHelper(path, page + 1, data, params);
      }
      return data;
  }

  add(path: string, resource: T | Partial<T>, params?: HttpParams): Observable<T> {
    return this.httpClient
      .post<T>(`${this.apiLocation}/${path}`, convertKeys.toSnake(resource), {
        params,
      })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }

  update(path: string, id: string | number, resource: T | Partial<T>, params?: HttpParams): Observable<T> {
    return this.httpClient
      .put<T>(`${this.apiLocation}/${path}/${id}`, convertKeys.toSnake(resource), {
        params,
      })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }

  delete(path: string, id: string | number, params?: HttpParams): Observable<T> {
    return this.httpClient
      .delete<T>(`${this.apiLocation}/${path}/${id}`, { params })
      .pipe(
        map(item => convertKeys.toCamel<T>(item['data'])),
        catchError(error => {
          return this.handleError(error);
        }),
      );
  }
}
