<div class="app-device-view content">
    <header class="sticky-header">
        <div class="left-column">
            <div class="icon-header">
                <div class="icon-container">
                    <mat-icon *ngIf="!loadingDevice">bookmarks</mat-icon>
                    <mat-spinner *ngIf="loadingDevice" matSuffix diameter="30" color="warn"></mat-spinner>
                </div>
                <h1
                    *ngIf="!loadingDevice"
                    id="view-title"
                    class="ellipsis"
                    data-automation="name"
                    title="{{ device.displayLabel }}"
                >
                    <ng-container *ngIf="!loadingDevice">{{ device.displayLabel }}</ng-container>
                    <ng-container *ngIf="loadingDevice">{{ 'device.loading_device' | translate }}</ng-container>
                </h1>
                <p *ngIf="!loadingDevice" class="subtitle" data-automation="subtitle">
                    {{ 'device.view.subtitle' | translate }}
                </p>
            </div>
        </div>
        <div class="right-column">
            <button
                class="icon-btn x-btn-secondary"
                color="accent"
                aria-label="delete"
                (click)="openDeleteDialog()"
                data-automation="delete"
                [disabled]="this.deviceId === ''"
            >
                <mat-icon>delete</mat-icon>
                <span class="btn-text"> {{ 'device.button.delete' | translate }}</span>
            </button>

            <button
                class="icon-btn x-btn-primary"
                color="accent"
                aria-label="Edit"
                (click)="handleEdit()"
                data-automation="deviceEdit"
            >
                <mat-icon>edit</mat-icon>
                <span class="btn-text"> {{ 'device.button.edit' | translate }}</span>
            </button>
        </div>
    </header>
    <div class="page-content" *ngIf="!loadingDevice">
        <form #view="ngForm" class="device-form">
            <div class="form-group">
                <div class="columns-containers">
                    <div class="left-column">
                        <!-- Mac Address -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="macAddress">{{ 'device.mac_address' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.macAddress || '-' }}
                            </div>
                        </div>
                        <!-- Gateway Type -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="gatewayType">{{ 'device.gateway_type' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ getGatewayType(device.gatewayType) }}
                            </div>
                        </div>
                        <!-- Site -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="site">{{ 'device.site' | translate }}:</mat-label>
                            <div class="field-value" matTooltip="{{ getSiteForDevice() }}" matTooltipPosition="above">
                                {{ getSiteForDevice() }}
                            </div>
                        </div>
                        <!-- Host -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="host">{{ 'device.host' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.host || '-' }}
                            </div>
                        </div>
                        <!-- Include In Calcs -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="includeInCalcs">{{ 'device.included_in_calcs' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.includeInCalcs || '-' }}
                            </div>
                        </div>
                        <!-- Emulated -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="emulated">{{ 'device.emulated' | translate }}:</mat-label>
                            <div class="field-value">
                                {{ device.emulated || '-' }}
                            </div>
                        </div>
                    </div>

                    <div class="right-column">
                        <!-- Installer Contact Id  -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="installerContactId">{{ 'device.installer_id' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.installerContactId || '-' }}
                            </div>
                        </div>
                        <!-- Gateway ID -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="gatewayId">{{ 'device.gateway_id' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.gatewayId || '-' }}
                            </div>
                        </div>

                        <!-- CMR ID -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="crmId">{{ 'device.crm_id' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.crmId || '-' }}
                            </div>
                        </div>

                        <!-- Port -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="port">{{ 'device.port' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ device.port || '-' }}
                            </div>
                        </div>

                        <!-- Status -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="status">{{ 'device.status' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ getStatusForDevice() | translate }}
                            </div>
                        </div>

                        <!-- Signal Strength -->
                        <div>
                            <div class="view-field-container small">
                                <mat-label class="field-label" data-automation="signalStrength">{{ 'device.signal_strength' | translate }}:</mat-label>
                                <div class="field-value" >
                                    {{ device.lastSrMetadata != null ? device.lastSrMetadata.signalRssi : '-' }}
                                    <mat-icon
                                            svgIcon="info"
                                            data-automation="signalStrengthTooltip"
                                            matSuffix
                                            class="tooltip-icon"
                                            matTooltip="{{'device.view.tooltip.signal_strength' | translate}}"
                                    >
                                    </mat-icon>
                                </div>
                            </div>
                            <mat-hint *ngIf="device.lastSrMetadata != null && device.lastSrMetadata.signalRssi <= -90">
                                {{ 'device.validation.signal_quality.range' | translate }}
                            </mat-hint>
                        </div>

                        <!-- Signal Quality -->
                        <div class="view-field-container small">
                            <mat-label class="field-label" data-automation="signalQuality">{{ 'device.signal_quality' | translate }}:</mat-label>
                            <div class="field-value" >
                                {{ (device.lastSrMetadata != null ? (adequateQuality(device.lastSrMetadata.signalRssi) || '-')  : '-') | translate}}
                            </div>
                        </div>
                    </div>
                </div>
                <div class="bottom-row">
                    <!-- Notes -->
                    <div class="view-field-container large">
                        <mat-label class="field-label notes" data-automation="notes">{{ 'device.notes' | translate }}:</mat-label>
                        <div class="field-value" >
                            {{ device.notes || '-' }}
                        </div>
                    </div>
                </div>
            </div>

            <div class="managed-equipment-container">
                <h4 class="form-header" data-automation="managedEquipment">
                    {{ 'device.managed_equipment' | translate }}
                </h4>
                <div class="form-group managed-equipment">
                    <!-- Meters -->
                    <div class="view-field-container small bottom">
                        <mat-label class="equipment-title">{{ 'device.meters' | translate }} </mat-label>
                        <div class="equipment-items">
                            <ng-container *ngFor="let equipment of equipments; let i = index">
                                <div
                                    class="field-value equipment-value"
                                    *ngIf="equipment.type_equipment === 'meters'"
                                    [attr.data-automation]="'meters_' + i"
                                >
                                    <a href="{{this.buildEquipmentUrl(equipment.id)}}" target="_blank">{{ equipment.displayLabel }}</a>
                                </div>
                            </ng-container>
                        </div>
                        <div class="empty-equipments" *ngIf="!hasMeters">
                            <div><mat-icon svgIcon="noData"></mat-icon></div>
                            <h6>{{ 'device.empty_meters_title' | translate }}</h6>
                            <div data-automation="empty-meters" class="empty-filler">
                                {{ 'device.empty_meter_description' | translate }}
                            </div>
                        </div>
                    </div>
                    <!-- Control Sets -->
                    <div class="view-field-container small bottom ">
                        <mat-label class="equipment-title">{{ 'device.control_sets' | translate }} </mat-label>
                        <div class="equipment-items">
                            <ng-container *ngFor="let equipment of equipments; let i = index">
                                <div
                                    class="field-value equipment-value"
                                    *ngIf="equipment.type_equipment === 'controlSets'"
                                    [attr.data-automation]="'controlSets' + i"
                                >
                                    <a href="{{this.buildEquipmentUrl(equipment.id)}}" target="_blank">{{ equipment.displayLabel }}</a>
                                </div>
                            </ng-container>
                        </div>
                        <div class="empty-equipments" *ngIf="!hasControlSets">
                            <div><mat-icon svgIcon="noData"></mat-icon></div>
                            <h6>{{ 'device.empty_control_sets_title' | translate }}</h6>
                            <div data-automation="empty-control-sets" class="empty-filler">
                                {{ 'device.empty_control_sets_description' | translate }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div *ngIf="device.emulated" class="interval-data-types-container">
                <div class="form-header interval-data-types-title">
                    <h4 data-automation="intervalDataTypes">
                        {{ 'device.emulated_configurations.interval_data_types' | translate }}
                    </h4>
                    <mat-icon
                        svgIcon="info"
                        data-automation="intervalDataTypesTooltip"
                        matSuffix
                        class="tooltip-icon"
                        matTooltip="{{ 'device.tooltip.interval_data_types' | translate }}"
                    >
                    </mat-icon>
                </div>
                <div class="form-group configuration">
                    <div
                        class="view-field-container small bottom"
                        *ngFor="let item of device.intervalDataTypes | keyvalue; let i = index"
                    >
                        <mat-label [attr.data-automation]="'channel_' + item.key" class="channel-title"
                            >{{ getChannelLabel(item) | translate }}
                        </mat-label>
                        <div
                            class="field-value equipment-value"
                            [attr.data-automation]="'channel_' + item.key + '_value'"
                        >
                            {{ item.value }}
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
</div>
