import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Context } from 'ngx-global-nav';
import {CookieService as NgxCookieService} from 'ngx-shared-services';
import { environment } from 'src/environments/environment.prod';
import { MatSidenav } from '@angular/material/sidenav';
import { SidenavService } from './shared/services/sidenav.service';
import { NavService } from './shared/services/global-nav.service';
import { Device } from '@model';
import { NativeService } from './services/mobileView.service';
import * as MobileDetect from 'mobile-detect';
import {MixPanelService} from './shared/services/mixpanel.service';
import {MatIconRegistry} from "@angular/material/icon";
import {DomSanitizer} from "@angular/platform-browser";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  @ViewChild('sidenav') public sidenav: MatSidenav;
  private mobileDetector = new MobileDetect((this.window as any).navigator.userAgent);
  programs: any[];
  sidenavOpen = true;
  selectedDeviceId: string;
  isInit = true;
  orgList: Context[] = [];
  devices: Device[] = [];
  navUrl = '';
  isMobile = false;

  constructor(
    @Inject('WINDOW') private window: Window,
    private translate: TranslateService,
    private cookieService: NgxCookieService,
    private sidenavService: SidenavService,
    private navService: NavService,
    private nativeService: NativeService,
    private mixpanelService: MixPanelService,
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer,
  ) {
    if (!this.nativeService.isMobileDevice()) {
      this.getNavUrl();
    }
    this.isMobile = this.nativeService.isMobileDevice();
    this.matIconRegistry.addSvgIcon(
        'noData',
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/no-data.svg'),
    );
    this.matIconRegistry.addSvgIcon(
        'info',
        this.domSanitizer.bypassSecurityTrustResourceUrl('/assets/svg/general-info.svg'),
    );
  }

  async getNavUrl() {
    this.navUrl = await this.navService.getNavUrl();
  }

  ngOnInit(): void {
    this.mixpanelService.viewPage();
    const { defaults } = environment;
    const locale = this.cookieService.getI18NLocale() || defaults.locale;
    const brand = this.cookieService.getI18NBrand() || defaults.brand.toUpperCase();
    this.translate.setDefaultLang(`${defaults.locale}.${defaults.brand.toUpperCase()}`);
    this.translate.use(`${locale}.${brand}`).subscribe(() => {
      this.translate.get('APP.TITLE').subscribe(result => {
        document.title = result;
      });
    });
  }

  ngAfterViewInit(): void {
    this.sidenavService.setSidenav(this.sidenav);
  }

  isLargeScreen() {
    const width = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    return width > 1280;
  }
}
