import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { CookieService } from './cookie.service';
import { ContextSelectorService } from 'ngx-global-nav';
import { AtlasPagesService } from 'atlas-pages-loader';

const SITE_HIERARCHY = ['organization', 'spacegroup', 'site'];

@Injectable()
export class SiteService {
  allOrgSites$: BehaviorSubject<any[]>;
  sites$ = new BehaviorSubject<any[]>([]);
  loadingSites$ = new BehaviorSubject<boolean>(true);
  atlasPageLoader: AtlasPagesService;
  cookieService: CookieService;
  contextSelectorService: ContextSelectorService;
  orgId: string;
  constructor(
    private httpClient: HttpClient,
    cookieService: CookieService,
    contextSelectorService: ContextSelectorService,
  ) {
    this.allOrgSites$ = new BehaviorSubject<any[]>([]);
    this.cookieService = cookieService;
    this.contextSelectorService = contextSelectorService;
    this.initPageLoader();
    this.contextSelectorService.currentContext$.subscribe(async context => {
      if (context && context[0]?.id) {
        this.orgId = context[0].id;
        this.getSites();
      }
    });
  }

  nodeHelper(node, sites: any[]) {
    //If node is site
    if (node?.spaceType && node.spaceType == 'Site') {
      //Add to sites and return
      sites.push(node);
      return;
    } else if (node?.spaceType && (node.spaceType == 'Spacegroup' || node.spaceType == 'Organization')) {
      if (node?.children) {
        node.children.forEach(child => {
          this.nodeHelper(child, sites);
        });
      }
    }
    return sites;
  }

  initPageLoader() {
    this.atlasPageLoader = new AtlasPagesService(
      this.cookieService.getMarketsMock(),
      this.cookieService.getEnocSession(),
      this.httpClient,
    );
  }

  async getSites() {
    this.loadingSites$.next(true);
    this.initPageLoader();
    if (this.orgId) {
      const FilterBy = { id: this.orgId, dr_type: 'ORGANIZATION' };
      const orgSites = await this.atlasPageLoader.getSpecificHierarchies(SITE_HIERARCHY, FilterBy);
      const sites = this.nodeHelper(orgSites[0], []);
      this.sites$.next((convertKeys.toCamel({ sites }) as any).sites);
      this.loadingSites$.next(false);
    }
  }
}
