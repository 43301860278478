<ng-container *ngIf="noDevices">
    <div class="empty-page">
        <mat-icon>warning</mat-icon>
      <h2>{{ 'device.list.none_configured' | translate }}</h2>
    </div>
</ng-container>

 <ng-container *ngIf="loading">
    <div class="empty-page">
        <mat-spinner diameter="60"></mat-spinner>
        <h2>{{ 'device.loading_device' | translate }}...</h2>
    </div>
</ng-container>
