import { Injectable } from '@angular/core';
import * as convertKeys from 'convert-keys';
import { ApiService } from './api.service';
import { BehaviorSubject } from 'rxjs';
import {TrendChannelMap} from "../model/trend-channel-lookup.model";

@Injectable()
export class LookupService {
    mappings$: BehaviorSubject<any>;

    constructor(private apiService: ApiService) {
        this.mappings$ = new BehaviorSubject<any>([]);
    }

    mapToChannel(trendId: number) {
        const mappings = this.mappings$.value;
        const matched: TrendChannelMap = mappings.find((map: TrendChannelMap) =>  map.trendId == trendId);
        return matched ? matched.deliveredChannelId : null;
    }


    async setTrendChannelMappings() {
        const mappings = await this.apiService.get('trend_channel_lookup');
        this.mappings$.next(convertKeys.toCamel(mappings));
    }
}
