import { Inject, Injectable } from '@angular/core';
import { CookieService } from 'ngx-shared-services';
import { BehaviorSubject } from 'rxjs';
import * as MobileDetect from 'mobile-detect';
import * as nsWebViewInterface from 'nativescript-webview-interface/www/nativescript-webview-interface';

/**
 * Interface for native mobile application
 */
@Injectable({ providedIn: 'root' })
export class NativeService {
  private ns = nsWebViewInterface; // force ns script to run
  private mobileDetector = new MobileDetect((this.window as any).navigator.userAgent);
  private _hasNSWrapper: boolean;
  private nativeLoadSubject: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(
    @Inject('WINDOW') private window: Window,
    private cookieService: CookieService
  ) {
    this.handleMobileView();
  }

  get hasNSWrapper(): boolean {
    return this._hasNSWrapper;
  }

  get onNativeLoadSubject(): BehaviorSubject<boolean> {
    return this.nativeLoadSubject;
  }

  handleMobileView(): void {
    if (this.isMobileDevice()) {
      (this.window as any).nsWebViewInterface.on('loaded', () => {
        this._hasNSWrapper = true;
        this.nativeLoadSubject.next(true);
        console.log("Running on Mobile");
      });

      (this.window as any).nsWebViewInterface.emit('webapp_loaded');
    }
  }

  handleLogout(): void {
    (this.window as any).nsWebViewInterface.emit('logout');
  }

  isMobileDevice(): boolean {
    return this.cookieService.getCookie('test_mobile') === 'true';
  }
}
