import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { ControlContainer, FormGroup, NgForm, FormControl, Validators, AbstractControl } from '@angular/forms';
import { CookieService as NgxCookieService } from 'ngx-shared-services';
import { LocalesService } from '../shared/services/locales.service';
import { SiteService } from './../shared/services/sites.service';
import { Locale, LocaleBaseConfig } from '../shared/model/locale.model';
import { GatewayTypesService } from '../shared/services/gateway.service';
import { ChannelsService } from '../shared/services/channels.service';
import { map, tap } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { mappedTypes, statuses } from '../shared/model/device';
import { DeviceService } from '../shared/services/devices.service';
import { ActivatedRoute } from '@angular/router';
import { Subscription, debounceTime } from 'rxjs';
import { FormValidatorService } from '../shared/services/form-validator.service';
import { EquipmentService } from '../shared/services/equipment.service';
import { CdkVirtualScrollViewport } from '@angular/cdk/scrolling';
import { MatAutocomplete } from '@angular/material/autocomplete';
import { LookupService } from '../shared/services/lookup.service';
import { EntityBulkCreationComponent } from '../dialogs/entity-bulk-creation/entity-bulk-creation.component';
import { EntityBulkCreationData } from '../dialogs/entity-bulk-creation/entity-bulk-creation.models';
import { MatDialog } from '@angular/material/dialog';
import { GranularitiesService } from '../shared/services/granularities.service';
import { PrecheckStatus } from "@model";
import { FilterTag, Thing } from "../shared/model/thing.model";

const ALLOWED_EQUIPMENTS = ['meter', 'controlset'];

@Component({
  selector: 'app-device-form',
  templateUrl: './device-form.component.html',
  styleUrls: ['./device-form.component.scss'],
  viewProviders: [{ provide: ControlContainer, useExisting: NgForm }],
})
export class DeviceFormComponent implements OnInit, AfterViewInit {
  readonly EDIT = 'edit';
  readonly CREATE = 'create';
  readonly mappedTypes = mappedTypes;
  readonly statuses = statuses;

  readonly DEFAULT_MAPPED_TYPE = 'FIXED';
  readonly EMULATED_CHANNELS_WHITELIST = ['1', '3165'];

  abstractControl: FormGroup;
  multidisplayLabels: any;
  intervalDataTypes: FormGroup;
  locales: Locale[];
  userLocale = 'en_US';
  deviceEquipmentIds = [];
  show = false;
  isGatewayLoading = false;
  isValidGateway = false;
  gatewayExists = false;
  noDevicesFound = false;

  site_label_to_display = '';
  auto_site_label_to_display = '';

  thingRegistrations: any[] = [];
  selectedThingRegistration: any;

  isThingRegistrationLoading = false;
  showPrechecks = false;

  prechecks = {
    gateway_id: PrecheckStatus.failed,
    environment_prefix: PrecheckStatus.failed,
    trend_id: PrecheckStatus.failed,
    channel: PrecheckStatus.failed,
  };

  deviceSelectionHeight = 240;

  multiLocaleConfig: LocaleBaseConfig = {
    supportedLocales: [new Locale()],
    locales: [new Locale()],
    defaultLocale: new Locale(),
    id: '',
    displayLabelKey: 'displayLabel',
    localeKey: 'localeName',
  };

  @ViewChild('multiLocaleLabels') multiLocaleDisplayLabels;
  @ViewChild('multilabels') multilabels: any;

  @ViewChild(CdkVirtualScrollViewport) viewport: CdkVirtualScrollViewport;
  selectedId = 0;
  @ViewChild('auto', { static: false }) autoc: MatAutocomplete;

  @Input() deviceForm: FormGroup;
  deviceId: string;

  @Input()
  set mode(mode: string) {
    this._mode = mode;
  }

  get mode() {
    return this._mode;
  }

  get isEditMode() {
    return this.mode === this.EDIT;
  }

  get isCreateMode() {
    return this.mode === this.CREATE;
  }

  get displayLabels() {
    return this.deviceForm.get('displayLabels');
  }

  get supportedLocales() {
    return this.deviceForm.get('supportedLocales');
  }

  get defaultLocale() {
    return this.deviceForm.get('defaultLocale');
  }

  get macAddress() {
    return this.deviceForm.get('macAddress');
  }

  get installerContactId() {
    return this.deviceForm.get('installerContactId');
  }

  get gatewayType() {
    return this.deviceForm.get('gatewayType');
  }

  get gatewayId() {
    return this.deviceForm.get('gatewayId');
  }

  get autoThingId() {
    return this.deviceForm.get('autoThingId');
  }

  get searchDeviceId() {
    return this.deviceForm.get('searchDeviceId');
  }

  get autoSiteId() {
    return this.deviceForm.get('autoSiteId');
  }

  get autoPulseMultiplier() {
    return this.deviceForm.get('autoPulseMultiplier');
  }

  get crmId() {
    return this.deviceForm.get('crmId');
  }

  get siteId() {
    return this.deviceForm.get('siteId');
  }

  get host() {
    return this.deviceForm.get('host');
  }

  get port() {
    return this.deviceForm.get('port');
  }

  get status() {
    return this.deviceForm.get('status');
  }

  get equipmentIds() {
    return this.deviceForm.get('equipmentIds');
  }

  get signalStrength() {
    return this.deviceForm.get('signalStrength');
  }

  get signalQuality() {
    return this.deviceForm.get('signalQuality');
  }

  get includeInCalcs() {
    return this.deviceForm.get('includeInCalcs');
  }

  get emulated() {
    return this.deviceForm.get('emulated');
  }

  get notes() {
    return this.deviceForm.get('notes');
  }

  get selectedChannels() {
    return this.deviceForm.get('channels').value;
  }

  get channels() {
    return this.channelsService.channels$.pipe(
      map((channels) => {
        return channels.filter((channel) => this.EMULATED_CHANNELS_WHITELIST.includes(channel.id));
      }),
    );
  }

  get gatewayTypes() {
    return this.gatewayTypesService.gatewayTypes$;
  }

  get sites() {
    return this.siteService.sites$.pipe(
      tap((sites) => {
        if (!sites.map((site) => site.id).includes(this.siteId.value) && sites.length > 0) {
          this.siteId.setValue('');
          this.equipmentIds.setValue([]);
        } else {
          const site = this.siteService.sites$.value.find((site) => site.id === this.siteId.value);
          if (site) {
            this.site_label_to_display = site.displayLabel;
          }
        }
      }),
    );
  }

  get equipments() {
    const selectedSite = this.siteId.value;
    if (selectedSite) {
      return this.equipmentService.equipment$.pipe(
        map((equipments) => {
          if (equipments && equipments.length > 0) {
            return equipments.filter((equipment) => {
              if (!equipment.equipmentType) {
                return false;
              } else if (!ALLOWED_EQUIPMENTS.includes(this.toLowerCase(equipment.equipmentType))) {
                return false;
              }
              return true;
            });
          }
          return [];
        }),
      );
    }
  }

  unpackEquipmentGroups(node, equipment: any[]) {
    if (node.drType && node.drType === 'Equipment') {
      equipment.push(node);
      return;
    } else if (node.children) {
      node.children.forEach((child) => {
        this.unpackEquipmentGroups(child, equipment);
      });
    }
    return equipment;
  }

  toLowerCase(str: string) {
    return str.replace(/_| |-/g, '').toLowerCase();
  }

  get loadingSites() {
    return this.siteService.loadingSites$;
  }

  get loadingEquipment() {
    return this.equipmentService.loadingEquipment$;
  }

  private _mode: string;
  private subscriptions: Subscription[] = [];
  displayLabelPlaceholder = '';
  displayLabelName = '';

  constructor(
    private localesService: LocalesService,
    private channelsService: ChannelsService,
    private lookupService: LookupService,
    private gatewayTypesService: GatewayTypesService,
    private ngxCookieService: NgxCookieService,
    private route: ActivatedRoute,
    private siteService: SiteService,
    private equipmentService: EquipmentService,
    private deviceService: DeviceService,
    private translateService: TranslateService,
    private formValidatorService: FormValidatorService,
    private dialog: MatDialog,
    private granularitiesService: GranularitiesService,
  ) {
    this.displayLabelPlaceholder = this.translateService.instant('device.create.placeholder.displayLabel');
    this.displayLabelName = this.translateService.instant('device.displayLabel');
    this.userLocale = this.ngxCookieService.getCookie('locale') || 'en_US';
    const routeSub = this.route.params.subscribe(async (params) => {
      if (params) {
        this.deviceId = params.id;
      }
    });
    this.subscriptions.push(routeSub);
  }

  ngOnInit() {
    this.loadDynamicLists();

    const localesSub = this.localesService.locales$.subscribe((locales) => {
      this.locales = locales;
      const locale = this.localesService.checkForLocale(this.userLocale, locales) || locales[0];
      this.multiLocaleConfig.defaultLocale = locale;

      if (this.isCreateMode) {
        this.defaultLocale.patchValue(locale.localeName);
        this.supportedLocales.setValue([locales[0]]);
        // Thing registration
        const thingRegistrationSub = this.deviceService.thingRegistrations$.subscribe((data) => {
          this.thingRegistrations = data || [];
          this.noDevicesFound = false;

          //Resize selection viewport
          if (this.thingRegistrations.length < 5) {
            this.deviceSelectionHeight = this.thingRegistrations.length * 48;
          } else {
            this.deviceSelectionHeight = 240;
          }
          if (this.thingRegistrations.length < 1 && this.searchDeviceId.value != '') {
            //Validate thing id form
            this.noDevicesFound = true;
          }
          this.isThingRegistrationLoading = false;
        });
        this.subscriptions.push(thingRegistrationSub);
      }

      if (this.isEditMode) {
        this.formValidatorService.triggerFormValidation(this.deviceForm);
        const deviceSub = this.deviceService.device$.subscribe(async (device) => {
          if (device) {
            this.multidisplayLabels = device.displayLabels;
            this.equipmentIds.setValue(device.equipmentIds);
            this.deviceEquipmentIds = [...device.equipmentIds];
            if (device.lastSrMetadata && device.lastSrMetadata.signalRssi) {
              this.signalStrength.setValue(device.lastSrMetadata.signalRssi);
            } else {
              this.signalStrength.setValue(null);
            }
            if (this.signalStrength.value) {
              this.signalQuality.setValue(
                this.translateService.instant(this.adequateQuality(this.signalStrength.value)),
              );
            }
            if (device.siteId) {
              this.equipmentService.getEquipment(device.siteId);
            }
            if (this.emulated.value) {
              this.buildEmulatedFormsControls(device.intervalDataTypes);
            }
            this.buildSupportedLocales();
          }
        });
        this.subscriptions.push(deviceSub);
      }
    });
    this.deviceService.signalData$.next(null);
    this.deviceService.activationData$.next(null);
    const gatewayActivationSub = this.deviceService.activationData$.subscribe((data) => {
      if (data) {
        //Validate gateway form
        this.gatewayId.setErrors(null);
        if (data.devices.length && data.devices[0].id != this.deviceId) {
          this.gatewayId.setErrors({ inUse: true });
        }
        //Check if gateway activation payload exists
        this.gatewayExists = !!data.activation.length;
        //Check if gateway is valid
        this.isValidGateway = this.gatewayId.valid && this.gatewayExists;
        //If gateway is valid load signal strength
        this.refreshSignalStrength();
      }
    });
    const signalDataSub = this.deviceService.signalData$.subscribe((data) => {
      if (data) {
        try {
          const strength = data.system_info.connectivity.connections[0].signal.rssi;
          this.signalStrength.setValue(strength);
          this.signalQuality.setValue(this.translateService.instant(this.adequateQuality(strength)));
        } catch (e) {
          this.signalQuality.setValue(null);
          this.signalStrength.setValue(null);
        } finally {
          this.isGatewayLoading = false;
          this.show = true;
        }
      }
    });
    this.subscriptions.push(localesSub, gatewayActivationSub, signalDataSub);
  }

  displayFn(thing: any): string {
    return thing.device_id;
  }
  registerSelection(value: any) {
    this.selectedThingRegistration = value;
    this.selectedId = this.thingRegistrations.findIndex((val) => val.id == value.id);
    this.handlePrechecks();
  }

  handlePrechecks() {
    const thing = this.selectedThingRegistration;

    // Gateway ID
    this.prechecks.gateway_id =
        (Object(thing).hasOwnProperty('device_id') && !!thing.device_id) ?
            PrecheckStatus.success : PrecheckStatus.failed;

    // Environment prefix
    this.prechecks.environment_prefix =
        (Object(thing).hasOwnProperty('parameters') &&
            Object(thing.parameters).hasOwnProperty('environment_prefix') &&
            !!thing.parameters.environment_prefix) ?
            PrecheckStatus.success : PrecheckStatus.failed;

    // Trend ID
    this.prechecks.trend_id =
        (Object(thing).hasOwnProperty('parameters') &&
            Object(thing.parameters).hasOwnProperty('filter_tag') &&
            thing.parameters.filter_tag.length > 0 &&
            Object(thing.parameters.filter_tag[0]).hasOwnProperty('id') &&
            !!thing.parameters.filter_tag[0].id) ?
            PrecheckStatus.success : PrecheckStatus.warn;

    // Channel ID
    if (this.prechecks.trend_id == PrecheckStatus.success) {
      const channel = this.lookupService.mapToChannel(thing.parameters.filter_tag[0].id);
      this.prechecks.channel = !!channel ? PrecheckStatus.success : PrecheckStatus.failed;
    } else {
      this.prechecks.channel = PrecheckStatus.warn;
    }
    this.showPrechecks = true;
  }

  canAutoCreate(): boolean {
    return (
      typeof this.searchDeviceId.value === 'object' &&
      this.searchDeviceId.value != null &&
      !!this.autoSiteId.value &&
      !this.failedPrechecks()
    );
  }

  passedPrechecks(): boolean {
    return (
      this.prechecks.trend_id != PrecheckStatus.success &&
      this.prechecks.gateway_id != PrecheckStatus.success &&
      this.prechecks.channel != PrecheckStatus.success &&
      this.prechecks.environment_prefix != PrecheckStatus.success
    );
  }

  failedPrechecks(): boolean {
    return (
        this.prechecks.trend_id == PrecheckStatus.failed ||
        this.prechecks.gateway_id == PrecheckStatus.failed ||
        this.prechecks.channel == PrecheckStatus.failed ||
        this.prechecks.environment_prefix == PrecheckStatus.failed
    );
  }

  warnedPrechecks(): boolean {
    return (
        this.prechecks.trend_id == PrecheckStatus.warn ||
        this.prechecks.gateway_id == PrecheckStatus.warn ||
        this.prechecks.channel == PrecheckStatus.warn ||
        this.prechecks.environment_prefix == PrecheckStatus.warn
    );
  }

  autoCreate() {
    this.openAutoCreateDialog();
  }

  openAutoCreateDialog(): void {
    const site = this.siteService.sites$.value.find((site) => site.id === this.autoSiteId.value);
    const granularities = this.granularitiesService.getGranularities();
    const thing_registration: Thing = this.selectedThingRegistration;
    // Default filter_tag trend id value
    if (!thing_registration.parameters.filter_tag || thing_registration.parameters.filter_tag.length === 0) {
      thing_registration.parameters.filter_tag = [{period: '300', id: '44100000', tag:'topic/test'}];
    }
    const data = new EntityBulkCreationData(
      thing_registration,
      site,
      this.autoPulseMultiplier.value,
      this.userLocale,
      granularities,
    );
    this.dialog.open(EntityBulkCreationComponent, {
      width: '600px',
      data,
    });
  }

  ngAfterViewInit() {
    const multilocalesSub = this.multiLocaleDisplayLabels.form.valueChanges.subscribe(() => {
      const newValue = this.multilabels._returnModel;
      setTimeout(() => {
        this.updateAndValidateDisplayLabels(newValue);
      }, 10);
    });
    const openSelectSub = this.autoc.opened.subscribe((opened: any) => {
      this.viewport.scrollToIndex(this.selectedId);
    });
    this.searchDeviceId.valueChanges.pipe(debounceTime(800)).subscribe((value) => {
      if (typeof value === 'string') {
        if (value == '') {
          this.showPrechecks = false;
          this.deviceService.clearThingRegistrations();
        } else {
          this.searchThingId(false);
        }
      }
    });
    this.subscriptions.push(multilocalesSub, openSelectSub);
  }
  updateAndValidateDisplayLabels(newValue) {
    this.displayLabels.setValue({ ...newValue });
    this.displayLabels.markAsTouched();
    const isValid = this.validateMultiLocale(newValue, this.multilabels);
    if (!isValid) {
      this.displayLabels.setErrors({ required: true });
      (this.multiLocaleDisplayLabels.form as FormControl).markAllAsTouched();
      (this.multiLocaleDisplayLabels.form as FormControl).updateValueAndValidity();
    } else {
      this.displayLabels.setErrors(null);
    }
  }

  getDefaultLocale() {
    if (this.locales && this.locales.length > 0) {
      return (
        this.locales.find((locale) => locale.localeName == this.defaultLocale.value) ||
        this.multiLocaleConfig.defaultLocale
      );
    }
    return this.multiLocaleConfig.defaultLocale;
  }

  adequateQuality(strength) {
    if (strength <= -90) {
      return 'device.quality_values.poor';
    }
    return 'device.quality_values.good';
  }

  validateMultiLocale(newValue, multiDisplaylabels) {
    if (Object.keys(newValue).length === 0) {
      return false;
    }
    let transforNewValueToArray = Object.getOwnPropertyNames(newValue);
    const locales = multiDisplaylabels.supportedLocales.map((locale) => locale.localeName);
    let result;
    if (locales.length !== transforNewValueToArray.length) {
      return false;
    }
    for (const locale of locales) {
      result = transforNewValueToArray.includes(locale);
      if (!result) {
        return false;
      }
    }
    for (let locale in newValue) {
      if (newValue[locale] == '' || newValue[locale] == undefined) {
        return false;
      }
    }
    return true;
  }

  transformMultiLocaleValue(multilocales) {
    const displayLabels = {};
    if (multilocales) {
      const locales = Object.keys(multilocales);
      if (locales) {
        for (const locale of locales) {
          const key = locale
            .replace('displayLabels_', '')
            .split('_')
            .map((item, i) => (i == 1 ? item.toUpperCase() : item))
            .join('_');
          displayLabels[key] = multilocales[locale];
        }
      }
    }
    return displayLabels;
  }

  limitLocaleSelectList(num: number, locale: Locale) {
    return this.supportedLocales.value.length > num - 1 && !this.supportedLocales.value.includes(locale);
  }

  getMinControl(channelId) {
    return this.deviceForm.get('intervalDataTypes').get(channelId).get('N');
  }

  getMaxControl(channelId) {
    if (this.deviceForm.get('intervalDataTypes').get(channelId).get('M')) {
      return this.deviceForm.get('intervalDataTypes').get(channelId).get('M');
    }
  }

  getMaxMinValidity(channelId) {
    if (this.getIntervalType(channelId) !== 'RANDOM') {
      return false;
    }
    const min = this.deviceForm.get('intervalDataTypes').get(channelId).get('N');
    const max = this.deviceForm.get('intervalDataTypes').get(channelId).get('M');
    return min.hasError('max') || max?.hasError('min');
  }

  updateDisplayLabels(status, newValue) {
    this.displayLabels.setValue({ ...newValue });
    this.displayLabels.markAsTouched();
    if (status == 'INVALID') {
      this.displayLabels.setErrors({ required: true });
    } else {
      this.displayLabels.setErrors(null);
    }
  }

  handleSupportedLocalesSelection() {
    const selectedLocales = this.supportedLocales.value.map((locale) => locale.localeName);
    if (!selectedLocales.includes(this.defaultLocale.value)) {
      this.defaultLocale.setValue(selectedLocales[0]);
    }
  }

  handleSiteSelection() {
    this.equipmentIds.setValue([]);
    this.equipmentService.getEquipment(this.siteId.value);

    //Tooltip logic
    const site = this.siteService.sites$.value.find((site) => site.id === this.siteId.value);
    if (site) {
      this.site_label_to_display = site.displayLabel;
    }
  }

  handleAutoSiteSelection() {
    //Tooltip logic
    const site = this.siteService.sites$.value.find((site) => site.id === this.autoSiteId.value);
    if (site) {
      this.auto_site_label_to_display = site.displayLabel;
    }
  }

  handleChannelSelection() {
    console.log(this.selectedChannels);

    const intervalDataTypes: FormGroup = this.deviceForm.controls.intervalDataTypes as FormGroup;
    const currentChannels = Object.keys(intervalDataTypes.controls);
    const removedChannels = currentChannels.filter(
      (channel) => !this.selectedChannels.find((selChannel) => selChannel == channel),
    );

    removedChannels.forEach((channel) => {
      intervalDataTypes.removeControl(channel);
    });

    this.selectedChannels.forEach((channelId) => {
      if (!intervalDataTypes.contains(channelId)) {
        const newInterval = new FormGroup({
          type: new FormControl(this.DEFAULT_MAPPED_TYPE, Validators.required),
          N: new FormControl(null, Validators.required),
        });
        intervalDataTypes.addControl(channelId, newInterval);
        this.formValidatorService.trigerControlValidation(intervalDataTypes.get(channelId).get('N'));
      }
    });
  }

  buildEmulatedFormsControls(intervalDataTypes) {
    //forms are built and values are dynamically
    //set for the "Emulated Configurations" section
    this.intervalDataTypes = new FormGroup({});
    this.deviceForm.addControl('intervalDataTypes', this.intervalDataTypes);
    const channelsIds = Object.keys(intervalDataTypes);
    this.deviceForm.addControl('channels', new FormControl([], Validators.required));
    this.deviceForm.get('channels').setValue(channelsIds);

    channelsIds.forEach((channel) => {
      let emulatedValues = intervalDataTypes[channel];
      emulatedValues = emulatedValues.replace(/[()]+/g, ',').split(',');
      this.intervalDataTypes.addControl(channel, new FormGroup({}));
      const internalDataTypesGroup = this.intervalDataTypes.get(channel) as FormGroup;
      internalDataTypesGroup.addControl('type', new FormControl([], Validators.required));
      internalDataTypesGroup.get('type').setValue(emulatedValues[0]);
      internalDataTypesGroup.addControl('N', new FormControl([], Validators.required));
      internalDataTypesGroup.get('N').setValue(Number(emulatedValues[1]));
      if (emulatedValues[0] == 'RANDOM') {
        internalDataTypesGroup
          .get('N')
          .setValidators([
            Validators.required,
            (control: AbstractControl) => Validators.max(this.getMaxValue(channel))(control),
          ]);
        internalDataTypesGroup.addControl(
          'M',
          new FormControl(Number(emulatedValues[2]), [
            Validators.required,
            (control: AbstractControl) => Validators.min(this.getMinValue(channel))(control),
          ]),
        );
        internalDataTypesGroup.get('M').setValue(Number(emulatedValues[2]));
      }
    });
  }

  handleEmulatedChange() {
    const emulated = this.deviceForm.controls.emulated.value;
    if (emulated) {
      this.intervalDataTypes = new FormGroup({});
      this.deviceForm.addControl('intervalDataTypes', this.intervalDataTypes);
      this.deviceForm.addControl('channels', new FormControl([], Validators.required));
      this.formValidatorService.trigerControlValidation(this.deviceForm.get('channels'));
    } else {
      this.deviceForm.removeControl('intervalDataTypes');
      this.deviceForm.removeControl('channels');
      this.intervalDataTypes = null;
    }
  }

  handleMappedTypeChange(channelId) {
    const intervalGroup = this.deviceForm.get('intervalDataTypes').get(channelId) as FormGroup;
    const type = intervalGroup.get('type').value;
    switch (type) {
      case 'FIXED':
        if (intervalGroup.contains('M')) {
          intervalGroup.removeControl('M');
        }
        break;
      case 'RANDOM':
        intervalGroup
          .get('N')
          .setValidators([
            Validators.required,
            (control: AbstractControl) => Validators.max(this.getMaxValue(channelId))(control),
          ]);
        intervalGroup.addControl(
          'M',
          new FormControl(null, [
            Validators.required,
            (control: AbstractControl) => {
              return Validators.min(this.getMinValue(channelId))(control);
            },
          ]),
        );
        this.formValidatorService.trigerControlValidation(intervalGroup.get('M'));
        break;
    }
  }

  getMinValue(channelId) {
    const min = this.deviceForm.get('intervalDataTypes').get(channelId).get('N');
    if (min) {
      return min.value;
    }
  }

  getMaxValue(channelId) {
    const max = this.deviceForm.get('intervalDataTypes').get(channelId).get('M');
    if (max) {
      return max.value;
    }
  }

  getIntervalType(channelId) {
    return this.deviceForm.get('intervalDataTypes').get(channelId).get('type').value;
  }

  onlyLocaleSelected(locale) {
    return this.supportedLocales.value.length == 1 && this.supportedLocales.value[0] == locale;
  }

  buildSupportedLocales() {
    if (this.supportedLocales.value && Object.keys(this.displayLabels?.value).length != 0 && this.locales) {
      this.supportedLocales.patchValue([]);
      const keys = Object.keys(this.displayLabels.value);
      for (let key of keys) {
        const localeFound = this.locales.find((locale) => locale.localeName === key);
        if (localeFound) {
          this.supportedLocales.patchValue([...this.supportedLocales.value, localeFound]);
        }
      }
    }
  }

  private async loadDynamicLists() {
    this.localesService.setLocales();
    this.channelsService.setChannels();
    this.gatewayTypesService.setGatewayTypes();
    this.granularitiesService.setGranularities();
    if (this.isEditMode) {
      this.deviceService.getDevice(this.deviceId);
    }
    if (this.isCreateMode) {
      this.lookupService.setTrendChannelMappings();
    }
  }
  /**
   * @param force Force refresh even on pristine field.
   */
  async refreshGetwayActivation(force: boolean) {
    if (this.gatewayId.value && (force || this.gatewayId.dirty)) {
      this.signalQuality.setValue(null);
      this.signalStrength.setValue(null);
      this.show = false;
      this.isGatewayLoading = true;
      this.unfocusField('gatewayId-field');
      this.gatewayId.setErrors({ loading: true });
      this.gatewayId.markAsPristine();
      await this.deviceService.getGatewayActivationData(this.gatewayId.value);
    }
  }

  async searchThingId(force: boolean) {
    if (this.searchDeviceId.value && (force || this.searchDeviceId.dirty)) {
      this.showPrechecks = false;
      this.isThingRegistrationLoading = true;
      if (force) {
        await this.deviceService.getThingRegistration(this.searchDeviceId.value.device_id);
      } else {
        await this.deviceService.getThingRegistration(this.searchDeviceId.value);
      }
    }
  }

  hideFeedback() {
    this.show = false;
  }

  hideThingIdFeedback() {
    this.noDevicesFound = false;
  }

  hidePrechecks() {
    this.showPrechecks = false;
  }

  async refreshSignalStrength() {
    await this.deviceService.fetchCurrentSignalStrength(this.gatewayId.value);
    this.signalStrength.updateValueAndValidity();
  }

  unfocusField(fieldId) {
    const field = document.getElementById(fieldId);
    setTimeout(function () {
      field.classList.remove('mat-focused');
    }, 100);
  }

  getEquipmentsPlaceholder(equipments) {
    if (!this.siteId.value) {
      return 'device.create.placeholder.select_site_first';
    }
    if (equipments.length <= 0) {
      return 'device.create.placeholder.no_equipments';
    }
    if (!equipments.some((equipment) => !equipment.siteServerId)) {
      return 'device.create.placeholder.no_availble_equipments';
    }
    return 'device.create.placeholder.managed_equipment';
  }

  getChannelDisplayLabel(channelId: string) {
    return this.channelsService.channels$.pipe(
      map((channels) => {
        if (channels) {
          const selectedChannel = channels.find((channel) => channel.id === channelId);
          if (selectedChannel && selectedChannel.displayLabel) {
            return selectedChannel.displayLabel;
          }
        }
        return '';
      }),
    );
  }

  updateFieldValidations(channelId) {
    const min = this.deviceForm.get('intervalDataTypes').get(channelId).get('N');
    this.formValidatorService.trigerControlValidation(min);
    const max = this.deviceForm.get('intervalDataTypes').get(channelId).get('M');
    if (max) {
      this.formValidatorService.trigerControlValidation(max);
    }
  }

  ngOnDestroy() {
    this.subscriptions.forEach((subscription) => {
      subscription.unsubscribe();
    });
  }

  protected readonly PrecheckStatus = PrecheckStatus;
}
