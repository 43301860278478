import { Subscription } from 'rxjs';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { DeviceService } from '../shared/services/devices.service';
import { Router } from '@angular/router';
import { OrganizationsService } from '../shared/services/organizations.service';
import { Device } from "@model";

@Component({
  selector: 'app-default',
  templateUrl: './default.component.html',
  styleUrls: ['./default.component.scss'],
})
export class DefaultComponent implements OnInit, OnDestroy {
  loading = true;
  noDevices = false;
  device: Device;
  devices;
  private subscriptions: Subscription[] = [];

  constructor(
    private router: Router,
    private deviceService: DeviceService,
    private organizationsService: OrganizationsService,
  ) {
    this.deviceService.selectedDeviceId$.next(null);
    if (this.organizationsService.isInit) {
      this.organizationsService.getOrgs();
    }
  }

  navigateToFirstDevice() {
    if (this.devices && this.devices.length > 0) {
      this.loading = true;
      this.noDevices = false;
      this.router.navigate([`details/${this.devices[0].id}/view`], {});
    } else {
      this.loading = false;
      this.noDevices = true;
    }
  }

  async ngOnInit() {
    const devicesSub = this.deviceService.devices$.subscribe(devices => {
      this.devices = devices;
      this.navigateToFirstDevice();
    });

    this.subscriptions = [devicesSub];
  }

  ngOnDestroy() {
    this.subscriptions.forEach(subscription => {
      subscription.unsubscribe();
    });
  }
}
