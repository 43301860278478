export class Channel {
  id: string;
  displayLabel: string;
  displayLabels: object;
  pointType: string;
  uomId: string;
  uomDisplayLabel: string;
  aggregationType: string;
  granularity: string;
  base_granularity: string;
  reporting_interval_ms: number;
  selected: boolean;
  description: string;
  registration?: { id: string };

  constructor(
    id: string,
    displayLabel: string,
    point_type: string,
    uom: any,
    aggregationType: string,
    granularity: string,
    base_granularity: string,
    reporting_interval_ms: number,
    description: string,
  ) {
    this.id = id;
    this.displayLabel = displayLabel;
    this.uomId = uom.id;
    this.pointType = point_type;
    this.uomDisplayLabel = uom.display_label;
    this.aggregationType = aggregationType;
    this.granularity = granularity;
    this.base_granularity = base_granularity;
    this.reporting_interval_ms = reporting_interval_ms;
    this.selected = false;
    this.description = description;
  }
}
