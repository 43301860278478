import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { GranularityModel } from '@model';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class GranularitiesService {
  granularities$: BehaviorSubject<GranularityModel[]>;

  constructor(private apiService: ApiService) {
    this.granularities$ = new BehaviorSubject<GranularityModel[]>([]);
  }

  async setGranularities() {
    this.granularities$.next(await this.apiService.get('granularities'));
  }

  getGranularities(): GranularityModel[] {
    return this.granularities$.getValue();
  }
}
